import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner,
  useIonAlert,
} from '@ionic/react';
import { checkmark } from 'ionicons/icons';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import LBSlider, {
  LBSliderRef,
} from '../../../../design-system/components/slider/LBSlider';
import { RootState } from '../../../../redux/reducers';
import {
  killBillUserSelector,
  redeemStateSelector,
} from '../../../../redux/selectors/killBillUser.selector';
import { KillBillOffersDTO } from '../../api/killbill.api';
import './PremiumOffer.css';

interface PremiumOfferProps extends ConnectedPremiumOfferProps {
  offer: KillBillOffersDTO;
  onRedeem: () => void;
}
const PremiumOffer = forwardRef<LBSliderRef, PremiumOfferProps>(
  ({ offer, onRedeem, redeemState, killBillUser }, sliderRef) => {
    const { t } = useTranslation();
    const [presentConditionAlert] = useIonAlert();

    const isClaimed = killBillUser?.claimedKillBillOffersIds?.includes(offer.id);

    const onSeeCondition = () =>
      presentConditionAlert({
        header: t('basics.fields.condition'),
        subHeader: offer.condition,
        buttons: [{ text: 'Ok' }],
      });

    return (
      <IonCard key={offer.id} className="welcome-offer-card">
        <IonCardHeader className="header">
          <div className="left">
            <IonCardTitle className="card-title-store">{offer.title}</IonCardTitle>
            <IonCardSubtitle className="card-description-store">
              {offer.description}
            </IonCardSubtitle>
            {!isClaimed && offer.condition && (
              <IonCardSubtitle onClick={onSeeCondition} className="card-condition-store">
                See Conditions
              </IonCardSubtitle>
            )}
          </div>
          {isClaimed ? (
            <div className="circle-validated">
              <IonIcon icon={checkmark}></IonIcon>
            </div>
          ) : null}
        </IonCardHeader>

        {!isClaimed && (
          <IonCardContent>
            {redeemState === 'claiming' && (
              <div className="spinner-wrapper">
                <IonSpinner name="crescent" />
              </div>
            )}
            {redeemState !== 'claiming' && (
              <LBSlider ref={sliderRef} onCompleteSwipe={onRedeem} />
            )}
          </IonCardContent>
        )}
      </IonCard>
    );
  },
);

const mapStateToProps = (state: RootState) => ({
  killBillUser: killBillUserSelector(state),
  redeemState: redeemStateSelector(state),
});

const connector = connect(mapStateToProps);

type ConnectedPremiumOfferProps = ConnectedProps<typeof connector>;

export default connector(PremiumOffer);
