import moment from 'moment';
import React from 'react';
import './StoreHours.css';

import { useTranslation } from 'react-i18next';

interface DAY_VALUE_TYPE {
  [key: string]: number;
}
interface DAY_STRING_TYPE {
  [key: string]: string;
}
interface Hours {
  open: string;
  close: string;
}

export interface StoreHoursProps {
  storeOpeningHours: {
    hours: { [key: string]: Hours[] };
  };
}

const DAY_VALUE: DAY_VALUE_TYPE = {
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 7,
};

const DAY_STRING: DAY_STRING_TYPE = {
  mon: 'mon',
  tue: 'tue',
  wed: 'wed',
  thu: 'thu',
  fri: 'fri',
  sat: 'sat',
  sun: 'sun',
};

const formatHour = (open: string, close: string) => {
  const openFormatted = moment(open).format('LT');
  const closeFormatted = moment(close).format('LT');
  return `${openFormatted} - ${closeFormatted}`;
};

const StoreHours: React.FC<StoreHoursProps> = ({ storeOpeningHours }) => {
  const { t } = useTranslation();
  const renderHours = (day: string, hoursArray: Hours[]) => {
    const isToday = moment().isoWeekday() === DAY_VALUE[day];
    return hoursArray.length ? (
      hoursArray.map((hour, index) => (
        <div key={index}>{formatHour(hour.open, hour.close)}</div>
      ))
    ) : (
      <div>{t('basics.common.closed')}</div>
    );
  };

  if (storeOpeningHours && storeOpeningHours.hours) {
    return (
      <>
        {Object.keys(storeOpeningHours.hours).map((day: string) => {
          return (
            <div key={day} className="timeTable">
              <div className="dayName">{t(`basics.days.${DAY_STRING[day]}`)}</div>
              <div className="time">{renderHours(day, storeOpeningHours.hours[day])}</div>
            </div>
          );
        })}
      </>
    );
  }
  return null;
};

export default StoreHours;
