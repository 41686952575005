export const isEmailValid = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email !== '' && re.test(email);
};

export const isPasswordValid = (password: string) => {
  const reg = /^(?=.*[A-Za-z])(?=.*[\d]).{8,}$/;
  return password !== '' && reg.test(password);
};
