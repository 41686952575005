import axios from 'axios';
import { APP_BASE_URL } from '../../../shared/constantes/app.constantes';
import { UserDTO } from '../../../shared/types/dto/user.dto';
import { LoginDTO, LoginResponseDTO, RegisterDTO } from '../types/dto/auth.dto';

const authInstance = axios.create({
  baseURL: `${APP_BASE_URL}/users`,
  timeout: 30000,
});

const authRegister = async (auth: RegisterDTO) => authInstance.post<UserDTO>('', auth);

const authLogin = async (auth: LoginDTO) =>
  await authInstance.post<LoginResponseDTO>('/login', auth);

const fetchUserData = (userId: string, token: string) =>
  authInstance.get<UserDTO>(`/${userId}`, {
    params: {
      access_token: token,
    },
  });

const logOut = (token: string) => {
  return authInstance.post(`${APP_BASE_URL}/users/logout`, undefined, {
    params: {
      access_token: token,
    },
  });
};

export const resetPassword = async (email: string) => {
  const BASE_URL = APP_BASE_URL.replace('/api', '');
  return await axios.post(`${BASE_URL}/request-password-reset`, {
    email,
  });
};

const lib = {
  authRegister,
  authLogin,
  fetchUserData,
  logOut,
  resetPassword,
};

export default lib;
