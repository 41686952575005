import { KillBillOffersDTO } from '../../main/offers/api/killbill.api';
import { KillBillUserDTO } from '../../main/offers/types/map.types';
import { FilterType } from '../../shared/constantes/categories.constantes';
import {
  KILLBILL_OFFERS_UPDATE,
  KILLBILL_USER_UPDATE,
  SET_IS_CLAIMING,
  SET_KILLBILL_USER_LOADING,
  SUCCESS_ANIMATION,
  UPDATE_FILTERS_MAP,
  UPDATE_FILTERS_OFFERS,
  UPDATE_NB_KILLBILL,
} from '../../shared/constantes/saga.constantes';
import { KillBillUserActions, RedeemState } from '../types/killBillUser.types';

export interface KillBillUserStateType {
  isKBUserLoading: boolean;
  redeemState?: RedeemState;
  killBillUser?: KillBillUserDTO | null;
  killBillOffers: KillBillOffersDTO[];
  nbKillBill?: number;
  filters: {
    map: FilterType['code'][];
    offers: FilterType['code'][];
  };
  successAnimation?: boolean;
}

const initialState: KillBillUserStateType = {
  isKBUserLoading: false,
  redeemState: undefined,
  killBillUser: undefined,
  nbKillBill: undefined,
  killBillOffers: [],
  filters: {
    map: [],
    offers: [],
  },
  successAnimation: undefined,
};

export default function reducer(
  state = initialState,
  action: KillBillUserActions,
): KillBillUserStateType {
  switch (action.type) {
    case KILLBILL_USER_UPDATE:
      return {
        ...state,
        killBillUser: action.killBillUser,
      };

    case SET_KILLBILL_USER_LOADING:
      return {
        ...state,
        isKBUserLoading: action.isKBUserLoading,
      };

    case KILLBILL_OFFERS_UPDATE:
      return {
        ...state,
        killBillOffers: action.kbOffers,
      };

    case SUCCESS_ANIMATION:
      return {
        ...state,
        successAnimation: action.success,
      };

    case UPDATE_FILTERS_OFFERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          offers: action.offersFilters,
        },
      };
    case UPDATE_FILTERS_MAP:
      return {
        ...state,
        filters: {
          ...state.filters,
          map: action.mapFilters,
        },
      };
    case SET_IS_CLAIMING:
      return {
        ...state,
        redeemState: action.redeemState,
      };

    case UPDATE_NB_KILLBILL:
      return {
        ...state,
        nbKillBill: action.nbKillBill,
      };
    default:
      return state;
  }
}
