import { FC } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import OffersList from '../components/offersList/OffersList';
import CardKb from '../components/cardKb/CardKb';
import './OffersPage.css';
import { RootState } from '../../../redux/reducers';
import { listFiltersSelector } from '../../../redux/selectors/killBillUser.selector';
import { connect, ConnectedProps } from 'react-redux';
import QuickFilters from '../../../shared/components/quickFilters/QuickFilters';

interface OffersPageProps extends ConnectedOffersListProps {}

const OffersPage: FC<OffersPageProps> = ({ filtersKBOffers }) => {
  return (
    <IonPage>
      <IonContent fullscreen className='page-offers'>
        <CardKb className="card" />
        <div className="container-chips">
          <QuickFilters isMap={false} />
        </div>
        <OffersList />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: RootState) => ({
  filtersKBOffers: listFiltersSelector(state),
});

const connector = connect(mapStateToProps);

type ConnectedOffersListProps = ConnectedProps<typeof connector>;

export default connector(OffersPage);
