import { call, put, SagaReturnType, select, takeLeading } from 'redux-saga/effects';
import {
  fetchCardsRestApi,
  fetchKillBillOffersAPI,
  fetchKillBillUserAPI,
  postClaimKillBillOffer,
} from '../../main/offers/api/killbill.api';
import {
  CLAIM_KILLBILL_OFFER,
  FETCH_KB_CARDS_REST,
  KILLBILL_OFFERS_FETCH,
  KILLBILL_USER_FETCH,
} from '../../shared/constantes/saga.constantes';
import {
  setIsClaimingAction,
  setNBKillBillAction,
  successAnimationAction,
  updateKillBillOffersAction,
  updateKillBillUserAction,
  updateKillBillUserLoadingAction,
} from '../actions/killBillUser.action';
import { userSelector } from '../selectors/user.selector';

import { readUser } from '../../main/auth/repository/auth.repository';
import { KILLBILL_ID } from '../../shared/constantes/app.constantes';
import { killBillUserSelector } from '../selectors/killBillUser.selector';

function* fetchKillBillOffersGenerator(action: {
  type: string;
}): Generator<any, void, any> {
  try {
    const { data: kbOffers }: SagaReturnType<typeof fetchKillBillOffersAPI> = yield call(
      fetchKillBillOffersAPI,
      KILLBILL_ID,
    );
    if (kbOffers) {
      const reversedOfferList = kbOffers.reverse();
      yield put(updateKillBillOffersAction(reversedOfferList));
    }
  } catch (e) {
    console.error('[SAGA][MAP][FETCH_KILLBILL_OFFERS] error : ', e);
  }
}

function* fetchKillBillUser(): Generator<any, void, any> {
  try {
    yield put(updateKillBillUserLoadingAction(true));
    const user = readUser();
    if (user && user.token) {
      const { data, status }: SagaReturnType<typeof fetchKillBillUserAPI> = yield call(
        fetchKillBillUserAPI,
        user.id,
        user.token,
      );
      if (status === 200) {
        yield put(updateKillBillUserAction(data));
      }
    }
  } catch (e) {
  } finally {
    yield put(updateKillBillUserLoadingAction(false));
  }
}

function* claimKillBillOffer(action: any): Generator<any, void, any> {
  try {
    yield put(setIsClaimingAction('claiming'));
    const killBillUser: SagaReturnType<typeof killBillUserSelector> = yield select(
      killBillUserSelector,
    );
    const user: SagaReturnType<typeof userSelector> = yield select(userSelector);
    if (!killBillUser) {
      return;
    }
    const { data }: SagaReturnType<typeof postClaimKillBillOffer> = yield call(
      postClaimKillBillOffer,
      killBillUser.id,
      action.payload,
      user?.token ?? '',
    );
    yield put(
      updateKillBillUserAction({ ...killBillUser, claimedKillBillOffersIds: data }),
    );
    yield put(successAnimationAction(true));
    yield put(setIsClaimingAction('done'));
  } catch (error) {
    console.error(error, '[KillBill Offer] Claim KillBillOffer');
    yield put(setIsClaimingAction('error'));
  }
}

function* fetchCardsRestGenerator() {
  const r: SagaReturnType<typeof fetchCardsRestApi> = yield call(fetchCardsRestApi);
  yield put(setNBKillBillAction(r));
}

export function* killBillSaga(): Generator<any, void, any> {
  yield takeLeading(KILLBILL_USER_FETCH, fetchKillBillUser);
  yield takeLeading(KILLBILL_OFFERS_FETCH, fetchKillBillOffersGenerator);
  yield takeLeading(CLAIM_KILLBILL_OFFER, claimKillBillOffer);
  yield takeLeading(FETCH_KB_CARDS_REST, fetchCardsRestGenerator);
}
