const OffersWave = () => (
  <svg
    width="390"
    height="40"
    viewBox="0 0 390 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 34.2727C6.66667 37.303 3.33333 37.303 0 34.2727V42H390V34.2727C386.667 37.303 383.333 37.303 380 34.2727C376.667 31.2424 373.333 31.2424 370 34.2727C366.667 37.303 363.333 37.303 360 34.2727C356.667 31.2424 353.333 31.2424 350 34.2727C346.667 37.303 343.333 37.303 340 34.2727C336.667 31.2424 333.333 31.2424 330 34.2727C326.667 37.303 323.333 37.303 320 34.2727C316.667 31.2424 313.333 31.2424 310 34.2727C306.667 37.303 303.333 37.303 300 34.2727C296.667 31.2424 293.333 31.2424 290 34.2727C286.667 37.303 283.333 37.303 280 34.2727C276.667 31.2424 273.333 31.2424 270 34.2727C266.667 37.303 263.333 37.303 260 34.2727C256.667 31.2424 253.333 31.2424 250 34.2727C246.667 37.303 243.333 37.303 240 34.2727C236.667 31.2424 233.333 31.2424 230 34.2727C226.667 37.303 223.333 37.303 220 34.2727C216.667 31.2424 213.333 31.2424 210 34.2727C206.667 37.303 203.333 37.303 200 34.2727C196.667 31.2424 193.333 31.2424 190 34.2727C186.667 37.303 183.333 37.303 180 34.2727C176.667 31.2424 173.333 31.2424 170 34.2727C166.667 37.303 163.333 37.303 160 34.2727C156.667 31.2424 153.333 31.2424 150 34.2727C146.667 37.303 143.333 37.303 140 34.2727C136.667 31.2424 133.333 31.2424 130 34.2727C126.667 37.303 123.333 37.303 120 34.2727C116.667 31.2424 113.333 31.2424 110 34.2727C106.667 37.303 103.333 37.303 100 34.2727C96.6667 31.2424 93.3333 31.2424 90 34.2727C86.6667 37.303 83.3333 37.303 80 34.2727C76.6667 31.2424 73.3333 31.2424 70 34.2727C66.6667 37.303 63.3333 37.303 60 34.2727C56.6667 31.2424 53.3333 31.2424 50 34.2727C46.6667 37.303 43.3333 37.303 40 34.2727C36.6667 31.2424 33.3333 31.2424 30 34.2727C26.6667 37.303 23.3333 37.303 20 34.2727C16.6667 31.2424 13.3333 31.2424 10 34.2727Z"
      fill="white"></path>
  </svg>
);

export default OffersWave;
