import { IonContent, IonPage, IonToast, useIonLoading } from '@ionic/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegUserCircle } from 'react-icons/fa';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authLoginAction } from '../../../../redux/actions/auth.action';
import { RootState } from '../../../../redux/reducers';
import {
  loggingStateSelector,
  userSelector,
} from '../../../../redux/selectors/user.selector';
import TextField from '../../../../shared/components/input/TextField';
import { ROUTES } from '../../../../shared/constantes/app.constantes';
import ForgotPwdButton from '../../components/forgotPwdButton/ForgotPwdButton';
import { isEmailValid } from '../../utils/validation.utils';
import './AuthLoginPage.css';

interface AuthLoginPageProps extends AuthLoginPageConnectedProps {}

const AuthLoginPage: React.FC<AuthLoginPageProps> = ({ user, authLogin, logging }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({ email: '', password: '' });
  const history = useHistory();
  const [present, dismiss] = useIonLoading();

  useEffect(() => {
    if (user) {
      dismiss();
      history.replace(ROUTES.profile);
    }
  }, [user, history, dismiss]);

  useEffect(() => {
    if (logging.isLogging) {
      present({
        message: t('account.alert.pending2'),
      });
    } else {
      dismiss();
    }
  }, [logging.isLogging, dismiss, present]);

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const isMailValid = isEmailValid(data.email);
  const [hasError, setHasError] = useState(false);

  const onClickRegisterHandler = () => history.push(ROUTES.register);

  const onClickLoginHandler = () => (isMailValid ? authLogin(data) : setHasError(true));

  return (
    <Fragment>
      <IonPage>
        <IonContent fullscreen className="auth-login-page">
          <h1 className="titre">{t('basics.actions.logIn')}</h1>
          <div className="icon">
            <FaRegUserCircle color="#c1a30b" fontSize="1.5em" />
          </div>
          <div className="create-account">
            <TextField
              className="form-field"
              label={t('basics.fields.email')}
              type="text"
              name="email"
              onChange={handleInputChange}
              error={hasError && !isMailValid ? t('account.validationErrors.email') : ''}
            />

            <TextField
              className="form-field"
              label={t('basics.fields.password')}
              type="password"
              name="password"
              onChange={handleInputChange}
            />
            <div className="create-account-btn">
              <button
                onClick={onClickLoginHandler}
                className="btn-auth"
                disabled={!(data.email && data.password)}>
                <span>{t('basics.actions.logIn')}</span> <FaRegUserCircle />
              </button>
            </div>

            <ForgotPwdButton className="create-account-forgot-pw" />

            <div className="account-separator">
              <span>{t('basics.junctionWords.or')}</span>
            </div>

            <button className="link-auth" onClick={onClickRegisterHandler}>
              {t('account.noAccount')} <span>{t('basics.actions.register')} </span>
            </button>
          </div>
        </IonContent>
        <IonToast
          position="top"
          isOpen={!logging.isLogging && !!logging.error}
          message={logging.error}
          duration={1500}
          color="warning"
          icon="warning-outline"
        />
      </IonPage>
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: userSelector(state),
  logging: loggingStateSelector(state),
});

const mapDispatchToProps = {
  authLogin: authLoginAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AuthLoginPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(AuthLoginPage);
