import {
  UPDATE_SUBSCRIPTION_STATE,
  UPDATE_USER_LOCATION,
  USER_UPDATE,
} from '../../shared/constantes/saga.constantes';
import { UserStateType } from '../reducers/user.reducer';

export const updateUserAction = (user: UserStateType['user']) => ({
  type: USER_UPDATE,
  user,
});

export const updateSubscriptionState = (
  subscriptionState: UserStateType['subscribingState'],
) => ({
  type: UPDATE_SUBSCRIPTION_STATE,
  payload: subscriptionState,
});

export const updateUserLocation = (lat: number, lng: number) => ({
  type: UPDATE_USER_LOCATION,
  lat,
  lng,
});

export const updateUserLocationId = (
  subscriptionState: UserStateType['location']['watchId'],
) => ({
  type: UPDATE_USER_LOCATION,
  payload: subscriptionState,
});
