import { FC } from 'react';
import Lottie from 'lottie-react';
import * as successAnimation from '../../../../../lottie/checkmark.json';
import * as failureAnimation from '../../../../../lottie/error.json';
import { connect, ConnectedProps } from 'react-redux';
import { successAnimationAction } from '../../../../redux/actions/killBillUser.action';
import './RedeemAnimation.css';

interface RedeemAnimationProps extends ConnectedRedeemAnimationProps {
  hasSuccess: boolean;
}

const RedeemAnimation: FC<RedeemAnimationProps> = ({
  hasSuccess,
  successAnimationAction,
}) => {
  return (
    <div className="redeem-animation">
      {hasSuccess ? (
        <Lottie
          animationData={successAnimation}
          style={{ height: '300px' }}
          onComplete={() => console.log('on Complete')}
          onLoopComplete={() => successAnimationAction(undefined)}
        />
      ) : (
        <Lottie
          animationData={failureAnimation}
          style={{ height: '300px' }}
          onLoopComplete={() => successAnimationAction(undefined)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  successAnimationAction,
};

const connector = connect(undefined, mapDispatchToProps);

type ConnectedRedeemAnimationProps = ConnectedProps<typeof connector>;

export default connector(RedeemAnimation);
