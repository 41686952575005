import { IonChip } from '@ionic/react';
import { FC } from 'react';
import './Chip.css';

interface ChipProps {
  text: string;
  isSelected: boolean;
  onClickChipHandler: () => void;
}

const Chip: FC<ChipProps> = ({ text, isSelected, onClickChipHandler }) => {
  return (
    <IonChip
      className={isSelected ? 'selected' : undefined}
      outline={true}
      onClick={onClickChipHandler}>
      {text}
    </IonChip>
  );
};

export default Chip;
