import {
  USER_UPDATE,
  UPDATE_LOGIN_STATE,
  UPDATE_SUBSCRIPTION_STATE,
  UPDATE_USER_LOCATION,
  UPDATE_USER_LOCATION_ID,
} from '../../shared/constantes/saga.constantes';
import { UserDTO } from '../../shared/types/dto/user.dto';
import { UserActions } from '../types/user.types';

export interface UserStateType {
  user?: UserDTO | null;
  logging: {
    isLogging: boolean;
    error: string;
  };
  location: {
    lat: number;
    lng: number;
    watchId: number | null;
  };
  subscribingState?: 'buying' | 'sharing';
  haveKillBill?: boolean;
}

const initialState: UserStateType = {
  user: undefined,
  logging: {
    isLogging: false,
    error: '',
  },
  location: {
    lat: 0,
    lng: 0,
    watchId: null,
  },
  subscribingState: undefined,
  haveKillBill: undefined,
};

export default function reducer(
  state = initialState,
  action: UserActions,
): UserStateType {
  switch (action.type) {
    case UPDATE_LOGIN_STATE:
      return {
        ...state,
        logging: {
          ...state.logging,
          ...action.payload,
        },
      };
    case USER_UPDATE:
      return {
        ...state,
        user: action.user,
      };

    case UPDATE_SUBSCRIPTION_STATE:
      return {
        ...state,
        subscribingState: action.payload,
      };

    case UPDATE_USER_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          lat: action.lat,
          lng: action.lng,
        },
      };

    case UPDATE_USER_LOCATION_ID:
      return {
        ...state,
        location: {
          ...state.location,
          watchId: action.payload,
        },
      };

    default:
      return state;
  }
}
