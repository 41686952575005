export interface coord {
  lat: number;
  lng: number;
}

export const getDistance = (coord1: coord, coord2: coord, unit: string) => {
  if (coord1.lat === coord2.lat && coord1.lng === coord2.lng) {
    return 0;
  } else {
    const radlat1 = (Math.PI * coord1.lat) / 180;
    const radlat2 = (Math.PI * coord2.lat) / 180;
    const theta = coord1.lng - coord2.lng;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) dist = 1;
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') dist = dist * 1.609344;
    if (unit === 'N') dist = dist * 0.8684;
    if (dist < 1) return Math.round(dist * 10) / 10;
    return Math.ceil(dist);
  }
};
