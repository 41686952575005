import { IonButton, IonCard } from '@ionic/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import { fetchKillBillUserAction } from '../../../redux/actions/killBillUser.action';
import { updateSubscriptionState } from '../../../redux/actions/user.action';
import { RootState } from '../../../redux/reducers';
import {
  killBillUserSelector,
  nbKillBillSelector,
} from '../../../redux/selectors/killBillUser.selector';
import { userSelector } from '../../../redux/selectors/user.selector';
import { ROUTES } from '../../../shared/constantes/app.constantes';

import './BuyPageContent.css';

interface BuyPageContentProps extends BuyPageContentConnectedProps {
  onShare?: () => void;
  isPopup: boolean;
}

const BuyPageContent: FC<BuyPageContentProps> = ({
  nbKillBill,
  subscriptionState,
  isPopup,
  onShare,
}) => {
  // @ts-ignore
  const kbNumbers = (nbKillBill !== 'NaN' && nbKillBill) || 12;
  const history = useHistory();
  const { t } = useTranslation();

  const onClickLoginHandler = () => history.push(ROUTES.login);
  const text =
    'Hi !\nThe Web Summit advantage Pass is out ! 🔥\n\nEnjoy gifts and discount in the best spots of Lisbon !\nBars, restaurants, activities, coffees !\n\nShare it to get it FREE !';

  const onClickShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'LittleBill',
          text,
          url: 'https://lisboa.littlebill.io',
        })
        .then(() => {
          if (onShare) {
            onShare();
          }
          subscriptionState('sharing');
          history.push(ROUTES.register);
        })
        .catch((error) => console.log('Error sharing', error));
    }
  };

  return (
    <div className="buy-page-content">
      <IonCard className="presentation-card">
        <div className="ion-card-title">
          {t('description.title')}
          <p className="share-title"> {t('killBill.sharing')}</p>
        </div>
        {!isPopup && (
          <div className="ion-card-subtitle" color="light">
            {t('description.placeholder')}
          </div>
        )}

        <div className="card-content" color="light">
          {t('description.kbNumbers', { kbNumbers: kbNumbers })}
        </div>
      </IonCard>

      <div className="buttons-container">
        {/* @ts-ignore */}
        {navigator.share ? (
          <>
            <IonButton fill="default" className="buy-button" onClick={onClickShare}>
              {t('basics.actions.activate')}
            </IonButton>
            <div className="account-separator">
              <span>{t('basics.junctionWords.or')}</span>
            </div>
          </>
        ) : (
          <>
            <IonButton
              fill="default"
              className="buy-button"
              onClick={() => {
                subscriptionState('sharing');
                history.push(ROUTES.register);
              }}>
              {t('account.createAccount')}
            </IonButton>
          </>
        )}
      </div>

      <div className="logo-container" onClick={onClickLoginHandler}>
        <button className="link-auth">
          {t('account.alreadyExist')} <span>{t('basics.actions.logIn')}</span>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: userSelector(state),
  killBillUser: killBillUserSelector(state),
  nbKillBill: nbKillBillSelector(state),
});

const mapDispatchToProps = {
  fetchKillBillUser: fetchKillBillUserAction,
  subscriptionState: updateSubscriptionState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type BuyPageContentConnectedProps = ConnectedProps<typeof connector>;

export default connector(BuyPageContent);
