import {
  call,
  put,
  putResolve,
  SagaReturnType,
  select,
  takeLeading,
} from 'redux-saga/effects';
import authApi from '../../main/auth/api/auth.api';
import { LoginDTO, RegisterDTO } from '../../main/auth/types/dto/auth.dto';
import {
  AUTH_LOGIN,
  AUTH_REGISTER,
  FETCH_USER_DATA,
  LOG_OUT,
} from '../../shared/constantes/saga.constantes';

import {
  clearUser,
  readUser,
  writeUser,
} from '../../main/auth/repository/auth.repository';
import { createKBLisbonAPI } from '../../main/offers/api/killbill.api';
import { UserDTO } from '../../shared/types/dto/user.dto';
import {
  fetchKillBillUserAction,
  updateKillBillUserAction,
  updateLoginStateAction,
} from '../actions/killBillUser.action';
import { updateUserAction } from '../actions/user.action';
import { subscriptionStateSelector, userSelector } from '../selectors/user.selector';

import i18n from 'i18next';

function* fetchUserDataGenerator() {
  const user = readUser();
  if (user) {
    yield put(updateUserAction(user));
    yield put(fetchKillBillUserAction());
  }
}

function* createKBLisbon(token: string) {
  const state: SagaReturnType<typeof subscriptionStateSelector> = yield select(
    subscriptionStateSelector,
  );
  if (state === 'sharing') {
    try {
      const response: SagaReturnType<typeof createKBLisbonAPI> = yield call(
        createKBLisbonAPI,
        token,
      );
      yield putResolve(updateKillBillUserAction(response.data));
      return true;
    } catch (e) {
      return false;
    }
  }
  return true;
}

function* authRegister(action: {
  type: string;
  auth: RegisterDTO;
}): Generator<any, void, any> {
  try {
    yield put(updateLoginStateAction({ isLogging: true, error: '' }));
    const { data }: SagaReturnType<typeof authApi.authRegister> = yield call(
      authApi.authRegister,
      action.auth,
    );
    const user = yield* authLogin({
      email: data.email,
      password: action.auth.password,
    });
    const success = yield* createKBLisbon(user?.token || '');
    if (success) {
      yield put(updateUserAction(user));
      yield put(updateLoginStateAction({ isLogging: false, error: '' }));
    } else {
      yield put(
        updateLoginStateAction({
          isLogging: false,
          error: i18n.t('killBill.error.creation'),
        }),
      );
    }
  } catch (e: any) {
    if (e.response?.status === 422) {
      yield put(
        updateLoginStateAction({ isLogging: false, error: i18n.t('account.exist') }),
      );
    } else {
      yield put(
        updateLoginStateAction({
          isLogging: false,
          error: i18n.t('basics.common.errorOccured'),
        }),
      );
    }
  }
}

function* authLogin(auth: LoginDTO) {
  const { data: authData }: SagaReturnType<typeof authApi.authLogin> = yield call(
    authApi.authLogin,
    auth,
  );

  const { data }: SagaReturnType<typeof authApi.fetchUserData> = yield call(
    authApi.fetchUserData,
    authData.userId,
    authData.id,
  );
  const user = { ...data, token: authData.id };
  const hasSuccess = writeUser(user);
  if (hasSuccess) {
    return user;
  }
  return null;
}

function* authLoginGenerator(action: {
  type: string;
  auth: LoginDTO;
}): Generator<any, void, any> {
  try {
    yield put(updateLoginStateAction({ isLogging: true, error: '' }));
    const user = yield* authLogin(action.auth);
    if (user) {
      yield* fetchUserDataGenerator();
      yield put(updateLoginStateAction({ isLogging: false, error: '' }));
    } else {
      yield put(
        updateLoginStateAction({
          isLogging: false,
          error: `${i18n.t('basics.common.errorOccured')} ${i18n.t(
            'basics.common.tryAgain',
          )}`,
        }),
      );
    }
  } catch (e) {
    yield put(
      updateLoginStateAction({
        isLogging: false,
        error: i18n.t('account.validationErrors.badCredentials'),
      }),
    );
  }
}

function* logOutUserGenerator(): Generator<any, void, any> {
  try {
    yield put(updateLoginStateAction({ isLogging: true, error: '' }));
    const user: UserDTO = yield select(userSelector);

    clearUser();
    yield call(authApi.logOut, user.token || '');
    yield put(updateUserAction(null));
    yield put(updateLoginStateAction({ isLogging: false, error: '' }));
    yield put(updateKillBillUserAction(null));
  } catch (e) {}
}

export function* sagaAuth(): Generator<any, void, any> {
  yield takeLeading(AUTH_REGISTER, authRegister);
  yield takeLeading(AUTH_LOGIN, authLoginGenerator);
  yield takeLeading(FETCH_USER_DATA, fetchUserDataGenerator);
  yield takeLeading(LOG_OUT, logOutUserGenerator);
}
