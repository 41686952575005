import { IonContent, IonPage } from '@ionic/react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
  subscriptionStateSelector,
  userSelector,
} from '../../../../redux/selectors/user.selector';

import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { logOutAction } from '../../../../redux/actions/auth.action';
import { RootState } from '../../../../redux/reducers';
import { killBillUserSelector } from '../../../../redux/selectors/killBillUser.selector';
import {
  LITTLEBILL_URL,
  ROUTES,
  STRIPE_LINK,
} from '../../../../shared/constantes/app.constantes';
import CardKb from '../../../offers/components/cardKb/CardKb';
import './ProfilePage.css';

interface ProfilePageProps
  extends ProfilePageConnectedProps,
    RouteComponentProps<{
      userId: string;
    }> {}

const ProfilePage: React.FC<ProfilePageProps> = ({
  killBillUser,
  subscribeState,
  user,
  logOut,
}) => {
  const { t } = useTranslation();
  const userName = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName} ${user?.lastName}`;
    }
    return user?.email;
  }, [user]);

  if (!user) return <Redirect to={ROUTES.buy} />;

  if (subscribeState === 'buying') {
    window.location.href = `${STRIPE_LINK}?prefilled_email=${user.email}&client_reference_id=${user.id}`;
    return null;
  }

  const onClickLogo = () => window.open(LITTLEBILL_URL, '_blank');

  return (
    <IonPage>
      <IonContent fullscreen className="profile-page">
        <div className="profil-page-content">
          {killBillUser ? (
            <>
              <p className="member-from">{t('account.menberSince')}</p>
              <p className="member-date">
                {moment(killBillUser?.dateSubscription).format('DD MMMM YYYY')}
              </p>
            </>
          ) : (
            <>
              <p className="hello-name">{t('profile.hello', { name: userName })}</p>
              <p className="buy-now">{t('profile.buyPass')}</p>
            </>
          )}

          <CardKb className="card" />
          <div className="logout-btn-container">
            <button className="btn-logout" onClick={logOut}>
              {t('basics.actions.logOut')}
            </button>
          </div>

          <div className="logo-container" onClick={onClickLogo}>
            <div className="logo">
              <div className="img"></div>
              <p className="text">
                Little<span>Bill</span>
              </p>
            </div>
            <div className="website">{t('profile.visit')}</div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: RootState) => ({
  killBillUser: killBillUserSelector(state),
  user: userSelector(state),
  subscribeState: subscriptionStateSelector(state),
});

const mapDispatchToProps = {
  logOut: logOutAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProfilePageConnectedProps = ConnectedProps<typeof connector>;

export default connector(ProfilePage);
