export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || '';
export const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL || '';
export const APP_BASE_ROUTER = process.env.REACT_APP_APP_BASE_ROUTER || '';
export const STRIPE_LINK = process.env.REACT_APP_STRIPE_LINK || '';
export const KILLBILL_ID = process.env.REACT_APP_KILLBILL_ID || '';
export const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION === 'true';

export const TOP_OFFER_IDS = IS_PRODUCTION
  ? [
      '2386f260c1c6a65277279d03', // Lime
      '31432da37bf4f243aa82ba71', // No Diet Club
      '454d93029f73f440a4f77fd0', // Noobai cafe
      '51eeb2e9b6c200ccf807920b', // TOPO,
      '1e00a9be22e06db77097a730', // Bike bar tour
      '54a0a98c99e538d0e125dbe2', // Rent a boat
      'c44d9887de3ddb508cdca2bc', // Ache cohiba
      '2c590916913d8f307bfd22b0', // Lisboa Rio 1
      '7037f56bbf8dfc26886b7c6d', // Lisboa Rio 2
      'c148720817e93f5bef8f3e42', // Ferro
    ]
  : ['7f2fae59d0146c6722d39036'];

export const ROUTES = {
  login: '/auth/login',
  register: '/auth/register',
  profile: '/auth/profile',
  validation: '/validation',
  map: '/map',
  buy: '/buy',
  offers: '/offers',
  offer: (id: string) => `/offers/${id}`,
  killbillCard: '/offer/:id/killbill-card',
};

export const LITTLEBILL_URL = 'https://littlebill.io';
