import {
  CLAIM_KILLBILL_OFFER,
  SUCCESS_ANIMATION,
  UPDATE_LOGIN_STATE,
  KILLBILL_OFFERS_FETCH,
  KILLBILL_OFFERS_UPDATE,
  UPDATE_FILTERS_OFFERS,
  UPDATE_FILTERS_MAP,
  SET_IS_CLAIMING,
  SET_KILLBILL_USER_LOADING,
  UPDATE_NB_KILLBILL,
  FETCH_KB_CARDS_REST,
} from '../../shared/constantes/saga.constantes';
import { KillBillOfferModel } from '../types/killBill.types';

import {
  KILLBILL_USER_FETCH,
  KILLBILL_USER_UPDATE,
} from '../../shared/constantes/saga.constantes';
import { UserStateType } from '../reducers/user.reducer';
import { KillBillOffersDTO } from '../../main/offers/api/killbill.api';
import { KillBillUserStateType } from '../reducers/killBillUser.reducer';
import { FilterType } from '../../shared/constantes/categories.constantes';
import { RedeemState } from '../types/killBillUser.types';

/**
 * FETCH DATA
 */

export const fetchKillBillOffersAction = () => ({
  type: KILLBILL_OFFERS_FETCH,
});

export const fetchKillBillUserAction = () => ({
  type: KILLBILL_USER_FETCH,
});

export const updateKillBillUserLoadingAction = (isLoading: boolean) => ({
  type: SET_KILLBILL_USER_LOADING,
  isKBUserLoading: isLoading,
});

export const fetchKbRestAction = () => ({
  type: FETCH_KB_CARDS_REST,
});

/**
 * SET DATA
 */
export const updateKillBillUserAction = (
  killBillUser: KillBillUserStateType['killBillUser'],
) => ({
  type: KILLBILL_USER_UPDATE,
  killBillUser,
});

export const updateKillBillOffersAction = (kbOffers: KillBillOffersDTO[]) => ({
  type: KILLBILL_OFFERS_UPDATE,
  kbOffers,
});

export const claimKillBillOffer = (offerId: KillBillOfferModel['id']) => ({
  type: CLAIM_KILLBILL_OFFER,
  payload: offerId,
});

export const successAnimationAction = (success?: boolean) => ({
  type: SUCCESS_ANIMATION,
  success,
});

export const updateLoginStateAction = (loggingState: UserStateType['logging']) => ({
  type: UPDATE_LOGIN_STATE,
  payload: loggingState,
});

export const updateOfferFilters = (offersFilters: FilterType['code'][]) => ({
  type: UPDATE_FILTERS_OFFERS,
  offersFilters,
});

export const updateMapFilters = (mapFilters: FilterType['code'][]) => ({
  type: UPDATE_FILTERS_MAP,
  mapFilters,
});

export const setIsClaimingAction = (redeemState: RedeemState) => ({
  type: SET_IS_CLAIMING,
  redeemState,
});

export const setNBKillBillAction = (nb: number) => ({
  type: UPDATE_NB_KILLBILL,
  nbKillBill: nb,
});
