import {
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonPage,
  IonSpinner,
  useIonAlert,
} from '@ionic/react';
import { useRef } from 'react';

import { BsFacebook, BsInstagram } from 'react-icons/bs';
import { FiArrowLeft, FiMail, FiPhone } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { Redirect, RouteComponentProps } from 'react-router';
import StoreHours, {
  StoreHoursProps,
} from '../../../../components/killBillStoreHours/StoreHours';
import { LBSliderRef } from '../../../design-system/components/slider/LBSlider';
import { KillBillOffersDTO } from '../api/killbill.api';
import OffersWave from '../components/OffersWave';
import './OfferPage.css';

import { eye, linkOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { claimKillBillOffer } from '../../../redux/actions/killBillUser.action';
import { RootState } from '../../../redux/reducers';
import {
  isKillBillUserLoadingSelector,
  killBillOffersSelector,
  killBillUserSelector,
  successAnimationSelector,
} from '../../../redux/selectors/killBillUser.selector';
import { KillBillOfferModel } from '../../../redux/types/killBill.types';
import { openMapsApp } from '../../../redux/utils/app.utils';
import { ROUTES } from '../../../shared/constantes/app.constantes';
import { CATEGORIES } from '../../../shared/constantes/categories.constantes';
import PremiumOffer from '../components/premiumOffer/PremiumOffer';
import RedeemAnimation from '../components/RedeemAnimation/RedeemAnimation';

interface OfferPageProps
  extends ConnectedOfferPageProps,
    RouteComponentProps<{
      id: string;
    }> {}

const OfferPage: React.FC<OfferPageProps> = ({
  match,
  showAnimation,
  killBillUser,
  killBillOffers,
  isKillBillUserLoading,
  claimKillBillOffer,
}) => {
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const sliderRef = useRef<LBSliderRef>(null);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const offer = killBillOffers.find((ko) => ko.id === match.params.id);
  const store = offer?.store;

  const isOfferCurrentStore = (ko: KillBillOffersDTO) => {
    return (
      ko &&
      ((ko.store && offer?.store && ko.storeId === offer.storeId) ||
        (offer?.franchiseId && ko.franchiseId === offer.franchiseId))
    );
  };

  const premiumOffers = killBillOffers.filter(
    (ko) => isOfferCurrentStore(ko) && ko.isPremium,
  );
  const annualOffers = killBillOffers.filter(
    (ko) => isOfferCurrentStore(ko) && !ko.isPremium,
  );

  const background = `url('${store?.picture || ''}')`;

  const onRedeem = (killBillOfferId: KillBillOfferModel['id']) => () => {
    presentAlert({
      header: t('offers.presence'),
      subHeader: t('offers.offerValidity'),
      buttons: [
        {
          text: t('basics.common.no'),
          handler: () => {
            if (sliderRef.current) {
              sliderRef.current.resetSlider();
            }
          },
        },
        {
          text: t('basics.common.yes'),
          handler: () => claimKillBillOffer(killBillOfferId),
        },
      ],
    });
  };

  const onClickAnnualOffer = (offer: KillBillOffersDTO) => () => {
    offer.link
      ? window.open(offer.link, '_blank')
      : history.push(`/offer/${id}/killbill-card`);
  };

  if (!killBillUser && !isKillBillUserLoading) {
    return <Redirect to={ROUTES.buy} />;
  }

  const category = CATEGORIES.find((category) => category.code === store?.categoryId);
  const { i18n: categoryTranslation } = category || { i18n: '' };

  return (
    <IonPage className="offer-page">
      {isKillBillUserLoading && (
        <div className="ion-spinner-wrapper">
          <IonSpinner name="crescent" />
        </div>
      )}

      {!isKillBillUserLoading && (
        <IonContent fullscreen>
          {showAnimation !== undefined && (
            <RedeemAnimation hasSuccess={!!showAnimation} />
          )}
          <div className="container-img" style={{ backgroundImage: background }}>
            <OffersWave />
            <button className="back-button" onClick={history.goBack}>
              <FiArrowLeft size="1.5rem" />
            </button>
          </div>
          <div className="container-information">
            <div className="shop-informations">
              <div className="txt-page-name-store"> {store?.name} </div>
              <div className="shop-informations-category">
                {t(`categories.${categoryTranslation}`)}{' '}
              </div>
              <div
                onClick={() => openMapsApp(offer?.store)}
                className="txt-page-adress-info">
                {store?.storeAddress?.line1 || ''}
              </div>
              <div className="text-page-description"> {store?.description || ''} </div>
              <br />
            </div>

            {!!premiumOffers.length && (
              <div className="txt-page-offre-bienvenue">{t('offers.welcomeOffers')}</div>
            )}
            {premiumOffers.map((premiumOffer) => (
              <PremiumOffer
                offer={premiumOffer}
                onRedeem={onRedeem(premiumOffer.id)}
                ref={sliderRef}
                key={premiumOffer.id}
              />
            ))}

            {!!annualOffers.length && (
              <div className="txt-page-offre-bienvenue">
                {t('offers.unlimitedOffers')}
              </div>
            )}
            {annualOffers.map((annualOffer) => (
              <IonCard onClick={onClickAnnualOffer(annualOffer)} key={annualOffer.id}>
                <IonCardContent>
                  <div className="card-unlimited-offer">
                    <div className="card-title-store card-unlimited-offer-title">
                      {annualOffer.title}
                    </div>
                    <div className="card-tooltip">
                      {annualOffer?.link ? (
                        <>
                          <IonIcon icon={linkOutline} />
                          <p>
                            {t('basics.actions.open')} {t('basics.common.link')}
                          </p>
                        </>
                      ) : (
                        <>
                          <IonIcon icon={eye} />
                          <p>
                            {t('basics.actions.show')} {t('basics.common.myCard')}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card-description-store">{annualOffer.description}</div>
                </IonCardContent>
              </IonCard>
            ))}
            <br />

            <h3 className="txt-page-location">{t('basics.common.location')}</h3>

            <div className="txt-page-addres" onClick={() => openMapsApp(offer?.store)}>
              <GoLocation color="#888F9B" size="1.2rem" />
              <p className="txt-page-adress-location">
                {store?.storeAddress?.line1 || ''}
              </p>
            </div>
            <br />

            <h3 className="txt-page-horaires">{t('basics.common.openingHours')}</h3>
            <div className="day">
              <StoreHours
                storeOpeningHours={
                  store?.storeOpeningHours as StoreHoursProps['storeOpeningHours']
                }
              />
            </div>
            <br />

            <h3 className="txt-page-contact">Contact</h3>
            {!!store?.phone && (
              <div className="contact-content">
                <FiPhone color="#707783" size="1.2rem" />
                <a href={`tel:${store?.phone}`}>{store?.phone}</a>
              </div>
            )}
            {!!store?.email && (
              <div className="contact-content">
                <FiMail color="#707783" size="1.2rem" />
                <a href={`mailto:${store?.email}`}>{store?.email}</a>
              </div>
            )}
            {!!store?.facebookLink && (
              <div className="contact-content">
                <BsFacebook color="#707783" size="1.2rem" />
                <a href={store?.facebookLink}>{store?.facebookLink}</a>
              </div>
            )}
            {!!store?.instagramLink && (
              <div className="contact-content">
                <BsInstagram color="#707783" size="1.2rem" />
                <a href={store?.instagramLink}>{store?.instagramLink}</a>
              </div>
            )}
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

const mapStateToProps = (state: RootState) => ({
  killBillUser: killBillUserSelector(state),
  killBillOffers: killBillOffersSelector(state),
  isKillBillUserLoading: isKillBillUserLoadingSelector(state),
  showAnimation: successAnimationSelector(state),
});

const mapDispatchToProps = {
  claimKillBillOffer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConnectedOfferPageProps = ConnectedProps<typeof connector>;

export default connector(OfferPage);
