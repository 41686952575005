import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import {
  killBillOffersSelector,
  killBillUserSelector,
} from '../../../../redux/selectors/killBillUser.selector';
import { KillBillOffersDTO } from '../../../offers/api/killbill.api';
import './MapCard.css';

interface MapCardProps extends ConnectedMapCardProps {
  offerId: string;
}
const MapCard: FC<MapCardProps> = ({ offerId, killBillOffers, killBillUser }) => {
  const { t } = useTranslation();
  const offer = killBillOffers.find((o) => o.id === offerId);

  const isOfferCurrentStore = (ko: KillBillOffersDTO) => {
    return (
      offer &&
      ((ko.store && offer.store && ko.storeId === offer.storeId) ||
        (offer.franchiseId && ko.franchiseId === offer.franchiseId))
    );
  };

  const storeOffers = killBillOffers
    .filter((ko) => isOfferCurrentStore(ko))
    .sort((a, b) => (a.isPremium ? 1 : 0));

  if (!offer) {
    return null;
  }

  return (
    <div className="content">
      <img src={offer.store?.logo} alt="store logo" />
      <div>
        <h3>{offer.store?.name}</h3>
        <p className="category">{offer.store?.categoryId}</p>
        {storeOffers[0] && <p className="offers">{storeOffers[0].title}</p>}
        {storeOffers.length > 1 && (
          <p className="more-offers">
            {t('offers.numberedOffers', { nb: `+ ${storeOffers.length - 1}` })}
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  killBillOffers: killBillOffersSelector(state),
  killBillUser: killBillUserSelector(state),
});

const connector = connect(mapStateToProps);

type ConnectedMapCardProps = ConnectedProps<typeof connector>;

export default connector(MapCard);
