export type FilterType = {
  i18n: string;
  code: string;
  icon: string;
};

export const CATEGORIES: FilterType[] = [
  { i18n: 'restaurants', code: 'food', icon: 'Restaurant' },
  {
    i18n: 'bars',
    code: 'bars-clubs',
    icon: 'Bars',
  },
  {
    i18n: 'transports',
    code: 'transport',
    icon: 'Transport',
  },
  {
    i18n: 'entertainment',
    code: 'entertainment',
    icon: 'Entertainment',
  },
  {
    i18n: 'coffeeBakeries',
    code: 'coffee',
    icon: 'Coffee',
  },
  {
    i18n: 'clubs',
    code: 'ws-club',
    icon: 'Club',
  },
  {
    i18n: 'beauty',
    code: 'beauty',
    icon: 'Beauty',
  },
];
