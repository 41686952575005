import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_lisbon from './Lisbon/en_lisbon.json';
import fr_lisbon from './Lisbon/fr_lisbon.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: { en: { translation: en_lisbon }, fr: { translation: fr_lisbon } },

    keySeparator: '.',
    nsSeparator: '|',

    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });
