import { KillBillOffersDTO } from '../../api/killbill.api';
import './OffersListItem.css';

interface OffersListItemProps {
  offer: KillBillOffersDTO;
  onClick: () => void;
  distanceFromUser?: number;
  isHidden: boolean;
}

const OffersListItem: React.FC<OffersListItemProps> = ({
  offer,
  onClick,
  distanceFromUser,
  isHidden,
}) => {
  return (
    <div className="list-item-wrapper" onClick={onClick}>
      <img className="image" src={offer.image} alt="offerPicture" />
      {distanceFromUser && <div className="distanceCard">{distanceFromUser} km</div>}
      <div className="cardFooter">
        <img className="logo" src={offer.store?.logo} alt="store logo" />
        <div className="cardFooterText">
          <div className="title">{offer.store?.name}</div>
          <div className="description">{offer.title}</div>
        </div>
      </div>
    </div>
  );
};

export default OffersListItem;
