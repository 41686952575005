import { createSelector } from 'reselect';
import { RootState } from '../reducers';

const userStateSelector = (state: RootState) => state.user;

export const userSelector = createSelector(
  userStateSelector,
  (userState) => userState.user,
);

export const loggingStateSelector = createSelector(
  userStateSelector,
  (userState) => userState.logging,
);

export const subscriptionStateSelector = createSelector(
  userStateSelector,
  (userState) => userState.subscribingState,
);

export const userLocationSelector = createSelector(
  userStateSelector,
  (userState) => userState.location,
);

export const userLocationIdSelector = createSelector(
  userStateSelector,
  (userState) => userState.location.watchId,
);
