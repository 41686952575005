import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonPage,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps, useHistory } from 'react-router';
import { RootState } from '../../../redux/reducers';
import {
  killBillOffersSelector,
  killBillUserSelector,
} from '../../../redux/selectors/killBillUser.selector';
import { userSelector } from '../../../redux/selectors/user.selector';
import { ROUTES } from '../../../shared/constantes/app.constantes';

import './KillBillCard.css';

interface KillBillCardProps
  extends ConnectedKillBillCardProps,
    RouteComponentProps<{
      id: string;
    }> {}

const KillBillCard: React.FC<KillBillCardProps> = ({
  match,
  user,
  killBillOffers,
  killBillUser,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [time, setTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const offer = killBillOffers.find(
    (killBillOffer) => killBillOffer.id === match.params.id,
  );

  const storeName = offer?.store?.name;

  const userName = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName} ${user?.lastName}`;
    }
    return user?.email;
  }, [user]);

  if (!killBillUser) {
    <Redirect to={ROUTES.buy} />;
  }

  return (
    <IonPage>
      <IonContent fullscreen className="killbill-card-page">
        <div className="kb-card-body">
          <button className="kb-card-button" onClick={() => history.goBack()}>
            <IonIcon icon={closeOutline} />
          </button>
          <h2 className="shop-name">{storeName}</h2>
          <p className="information">{t('killBill.presentOffer')}</p>

          <IonCard className="kb-card">
            <IonCardHeader>
              <IonCardTitle className="kb-gold kb-card-title">
                {offer?.title}
              </IonCardTitle>
            </IonCardHeader>

            <IonCardContent className="kb-card-content">
              {offer?.description}
            </IonCardContent>
          </IonCard>

          <IonCard className="kb-card">
            <IonCardHeader>
              <p className="kb-gold kb-card-title">{userName}</p>
            </IonCardHeader>

            <IonCardContent className="kb-gold kb-card-time">
              <div>{moment(time).format('HH:mm:ss')}</div>
              <p className="kb-gold kb-card-date">
                {moment(time).format('dddd, MMMM Do YYYY')}
              </p>
              <p className="kb-card-expire kb-gold">11/06</p>
            </IonCardContent>
          </IonCard>
          <div className="kb-card-bottom">
            <img
              className="kb-card-icon"
              src="/assets/imgs/icon.png"
              alt="Killbill icon"
            />
            <p className="killbill-name">
              Little<span className="kb-card-span">Bill</span>
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
const mapStateToProps = (state: RootState) => ({
  killBillUser: killBillUserSelector(state),
  user: userSelector(state),
  killBillOffers: killBillOffersSelector(state),
});

const connector = connect(mapStateToProps);

type ConnectedKillBillCardProps = ConnectedProps<typeof connector>;

export default connector(KillBillCard);
