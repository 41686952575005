import { IonListHeader, IonText, useIonAlert } from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../../../redux/reducers';
import {
  killBillOffersSelector,
  killBillUserSelector,
  listFiltersSelector,
} from '../../../../redux/selectors/killBillUser.selector';
import { userLocationSelector } from '../../../../redux/selectors/user.selector';
import { ROUTES, TOP_OFFER_IDS } from '../../../../shared/constantes/app.constantes';
import { getDistance } from '../../../../shared/utils/getDistance';
import { KillBillOffersDTO } from '../../api/killbill.api';
import OffersListItem from '../offersListItem/OffersListItem';
import './OffersList.css';

const OffersList: FC<ConnectedOffersListProps> = ({
  killBillOffers,
  killBillUser,
  filters,
  userLocation,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const topOffers = killBillOffers.filter((k) => TOP_OFFER_IDS.includes(k.id));

  const [filteredOffers, setFilteredOffers] =
    useState<KillBillOffersDTO[]>(killBillOffers);

  const [filteredTopOffers, setFilteredTopOffers] =
    useState<KillBillOffersDTO[]>(topOffers);

  const onClickBuy = () => history.push(ROUTES.buy);

  const showAlert = (id: string) => {
    if (killBillUser) {
      history.push(ROUTES.offer(id));
    } else {
      presentAlert({
        message:
          "You don't have the Web Summit pass yet. Join us and enjoy the advantages !",
        buttons: [
          {
            text: t('basics.actions.buyLater'),
            cssClass: 'alert-btn-buy-later',
            role: 'cancel',
          },
          {
            text: t('basics.actions.buyNow'),
            cssClass: 'alert-btn-buy-now',
            role: 'confirm',
            handler: onClickBuy,
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (filters.length > 0) {
      setFilteredOffers(
        killBillOffers.filter(
          (offer) => offer.store?.categoryId && filters.includes(offer.store?.categoryId),
        ),
      );
      setFilteredTopOffers(
        topOffers.filter(
          (topOffer) =>
            topOffer.store?.categoryId && filters.includes(topOffer.store?.categoryId),
        ),
      );
    } else {
      setFilteredOffers(killBillOffers);
      setFilteredTopOffers(topOffers);
    }
  }, [filters]);

  useEffect(() => {
    if (userLocation) {
      filteredOffers.sort(
        (a, b) =>
          getDistance(userLocation, a.store?.location!, 'K') -
          getDistance(userLocation, b.store?.location!, 'K'),
      );
      filteredTopOffers.sort(
        (a, b) =>
          getDistance(userLocation, a.store?.location!, 'K') -
          getDistance(userLocation, b.store?.location!, 'K'),
      );
      setFilteredOffers([...filteredOffers]);
      setFilteredTopOffers([...filteredTopOffers]);
    }
  }, [userLocation]);

  useEffect(() => {
    const topOffers = killBillOffers.filter((k) => TOP_OFFER_IDS.includes(k.id));
    setFilteredOffers(killBillOffers);
    setFilteredTopOffers(topOffers);
  }, [killBillOffers]);

  return (
    <>
      <IonListHeader>{t('offers.topOffers')}</IonListHeader>
      <div className="list-container top-offers">
        <>
          {filteredTopOffers.length > 0 ? (
            <>
              {filteredTopOffers.map((topOffer: any) => (
                <OffersListItem
                  isHidden={!killBillUser}
                  onClick={() => showAlert(topOffer.id)}
                  key={topOffer.id}
                  offer={topOffer}
                  distanceFromUser={
                    userLocation && topOffer.store?.location
                      ? getDistance(userLocation, topOffer.store.location, 'K')
                      : undefined
                  }
                />
              ))}
            </>
          ) : (
            <IonText color="medium">
              <p>{t('offers.noOffers')}</p>
            </IonText>
          )}
        </>
      </div>

      <IonListHeader>{t('offers.allOffers')}</IonListHeader>
      <div className="list-container">
        <>
          {filteredOffers.length > 0 ? (
            <>
              {filteredOffers.map((KBOffer) => (
                <OffersListItem
                  isHidden={!killBillUser}
                  onClick={() => showAlert(KBOffer.id)}
                  key={KBOffer.id}
                  offer={KBOffer}
                  distanceFromUser={
                    userLocation && KBOffer.store?.location
                      ? getDistance(userLocation, KBOffer.store?.location, 'K')
                      : undefined
                  }
                />
              ))}
            </>
          ) : (
            <IonText color="medium">
              <p>{t('offers.noOffers')}</p>
            </IonText>
          )}
        </>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  killBillUser: killBillUserSelector(state),
  killBillOffers: killBillOffersSelector(state),
  filters: listFiltersSelector(state),
  userLocation: userLocationSelector(state),
});

const connector = connect(mapStateToProps);

type ConnectedOffersListProps = ConnectedProps<typeof connector>;

export default connector(OffersList);
