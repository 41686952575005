import React, { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { userSelector } from '../../../redux/selectors/user.selector';
import { ROUTES } from '../../../shared/constantes/app.constantes';

interface RequiredAuthProps extends RequiredAuthConnectedProps {
  children: ReactNode;
}

const RequiredAuth: React.FC<RequiredAuthProps> = ({ user }) => {
  const location = useLocation();
  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Redirect to={{ pathname: ROUTES.buy, state: { referrer: location } }} />;
  }
  return <Redirect to={{ pathname: ROUTES.profile, state: { referrer: location } }} />;
};

const mapStateToProps = (state: RootState) => ({
  user: userSelector(state),
});

const connector = connect(mapStateToProps);

type RequiredAuthConnectedProps = ConnectedProps<typeof connector>;

export default connector(RequiredAuth);
