import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import {
  updateMapFilters,
  updateOfferFilters,
} from '../../../redux/actions/killBillUser.action';
import { RootState } from '../../../redux/reducers';
import {
  listFiltersSelector,
  mapFiltersSelector,
} from '../../../redux/selectors/killBillUser.selector';
import { CATEGORIES, FilterType } from '../../constantes/categories.constantes';
import Chip from '../Chip';
interface QuickFiltersProps extends ConnectedQuickFiltersProps {
  isMap: boolean;
}

const QuickFilters: FC<QuickFiltersProps> = ({
  mapFilters,
  listFilters,
  updateMapFilters,
  updateOfferFilters,
  isMap,
}) => {
  const { t } = useTranslation();
  const updateAction = isMap ? updateMapFilters : updateOfferFilters;
  const selectedFilters = isMap ? mapFilters : listFilters;

  const onClickChip = (selectedFilter: FilterType) => () => {
    if (selectedFilters.includes(selectedFilter.code)) {
      const filters = selectedFilters.filter((filter) => filter !== selectedFilter.code);
      updateAction(filters);
    } else {
      updateAction([...selectedFilters, selectedFilter.code]);
    }
  };

  return (
    <>
      {CATEGORIES.map((filter) => (
        <Chip
          key={filter.code}
          text={t(`categories.${filter.i18n}`)}
          onClickChipHandler={onClickChip(filter)}
          isSelected={selectedFilters.includes(filter.code)}
        />
      ))}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  mapFilters: mapFiltersSelector(state),
  listFilters: listFiltersSelector(state),
});

const mapDispatchToProps = {
  updateMapFilters,
  updateOfferFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConnectedQuickFiltersProps = ConnectedProps<typeof connector>;

export default connector(QuickFilters);
