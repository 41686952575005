import { LoginDTO, RegisterDTO } from '../../main/auth/types/dto/auth.dto';
import {
  AUTH_LOGIN,
  AUTH_REGISTER,
  FETCH_USER_DATA,
  LOG_OUT,
} from '../../shared/constantes/saga.constantes';

export const fetchUserDataAction = () => ({
  type: FETCH_USER_DATA,
});

export const authRegisterAction = (auth: RegisterDTO) => ({
  type: AUTH_REGISTER,
  auth,
});

export const authLoginAction = (auth: LoginDTO) => ({
  type: AUTH_LOGIN,
  auth,
});

export const logOutAction = () => ({
  type: LOG_OUT,
});
