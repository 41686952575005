import { GoogleMap, Marker } from '@react-google-maps/api';
import { FC, useState } from 'react';
import { MapStyle } from './MapStyle';
import { KillBillOffersDTO } from '../../../offers/api/killbill.api';
import { CATEGORIES } from '../../../../shared/constantes/categories.constantes';
import { RootState } from '../../../../redux/reducers';
import { userLocationSelector } from '../../../../redux/selectors/user.selector';
import { connect, ConnectedProps } from 'react-redux';
import { killBillUserSelector } from '../../../../redux/selectors/killBillUser.selector';

const INIT_CENTER = { lat: 38.7166123, lng: -9.146685 };
const DEFAULT_ZOOM = 13;
const MAX_ZOOM = 16;

interface MapProps {}

interface MapProps extends ConnectedMapProps {
  killBillOffers: KillBillOffersDTO[];
  onMarkerClick: (offer: KillBillOffersDTO) => void;
  onMapClick: () => void;
}

export const Map: FC<MapProps> = ({
  killBillOffers,
  onMarkerClick,
  onMapClick,
  userLocation,
  killBillUser,
}) => {
  const [center, setCenter] = useState(INIT_CENTER);

  const handleClick = (offer: KillBillOffersDTO) => () => {
    if (offer.store) {
      setCenter({
        lat: offer.store.location.lat,
        lng: offer.store.location.lng,
      });
    }
    onMarkerClick(offer);
  };

  const findCategory = (category: string) => {
    const filteredCat = CATEGORIES.find((cat) => cat.code === category);
    return filteredCat ? filteredCat.icon : '';
  };

  return (
    <GoogleMap
      center={center}
      zoom={DEFAULT_ZOOM}
      onClick={onMapClick}
      mapContainerStyle={{ width: '100%', height: '100%' }}
      options={{
        maxZoom: killBillUser ? undefined : MAX_ZOOM,
        styles: MapStyle,
        disableDefaultUI: true,
        zoomControl: true,
        clickableIcons: false,
        controlSize: 25,
      }}>
      {userLocation && (
        <Marker
          zIndex={999}
          icon={'/assets/imgs/user_location.svg'}
          key="marker"
          position={{ lat: userLocation.lat, lng: userLocation.lng }}
        />
      )}
      {killBillOffers.map((item, index) => {
        if (item.store) {
          return (
            <Marker
              icon={`/assets/imgs/marker${findCategory(item.store.categoryId)}.svg`}
              key={item.id}
              position={{ lat: item.store.location.lat, lng: item.store.location.lng }}
              onClick={handleClick(item)}
            />
          );
        }
        return null;
      })}
    </GoogleMap>
  );
};

const mapStateToProps = (state: RootState) => ({
  userLocation: userLocationSelector(state),
  killBillUser: killBillUserSelector(state),
});

const connector = connect(mapStateToProps);

type ConnectedMapProps = ConnectedProps<typeof connector>;

export default connector(Map);
