import { combineReducers } from 'redux';
import user from './user.reducer';
import killBill from './killBillUser.reducer';
const reducerInstance = combineReducers({
  user,
  killBill,
});

export type RootState = ReturnType<typeof reducerInstance>;

export default reducerInstance;
