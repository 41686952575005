import { ChangeEventHandler, FC } from 'react';
import './TextField.css';

interface TextFieldProps {
  type?: React.HTMLInputTypeAttribute;
  error?: string;
  name?: string;
  className?: string;
  label: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const TextField: FC<TextFieldProps> = ({
  onChange,
  label,
  type,
  className,
  name,
  error,
}) => {
  return (
    <div className={`lb-textfield ${className || ''}`}>
      <label htmlFor="" className="create-account-content-label label-pw">
        {label}
      </label>
      <input type={type} name={name} onChange={onChange} />
      {error && <div className="lb-textfield-error-message">{error}</div>}
    </div>
  );
};

export default TextField;
