import { USER_KEY } from '../../../shared/constantes/storage.constantes';
import { UserDTO } from '../../../shared/types/dto/user.dto';

export const readUser = () => {
  const userStr = localStorage.getItem(USER_KEY);
  if (userStr) {
    const user: UserDTO = JSON.parse(userStr);
    return user;
  }
  return null;
};

export const writeUser = (user: UserDTO) => {
  try {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    return true;
  } catch (error) {
    return false;
  }
};

export const clearUser = () => localStorage.removeItem(USER_KEY);
