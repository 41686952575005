import axios from 'axios';
import { KillBillOfferModel } from '../../../redux/types/killBill.types';
import {
  APP_BASE_ROUTER,
  APP_BASE_URL,
  KILLBILL_ID,
} from '../../../shared/constantes/app.constantes';
import { FilterType } from '../../../shared/constantes/categories.constantes';
import { KillBillUserDTO } from '../types/map.types';

export interface StoreDTO {
  location: {
    lat: number;
    lng: number;
  };
  name: string;
  description: string;
  phone: string;
  email: string;
  website: string;
  logo: string;
  picture: string;
  categoryId: FilterType['code'];
  storeAddress: AddressDTO;
  storeOpeningHours: {
    hours: any;
  };
  facebookLink: string;
  instagramLink: string;
}
interface AddressDTO {
  line1: string;
  line2: string;
  line3: string;
  city: string;
  stateOrRegion: string;
  zipOrPostcode: string;
  countryCode: string;
  country: string;
}

export interface KillBillOffersDTO {
  isPremium: boolean;
  title: string;
  description: string;
  condition?: string;
  image: string;
  link?: string;
  id: string;
  storeId?: string;
  franchiseId?: string;
  store?: StoreDTO;
  franchise?: FranchiseDTO;
}

export interface Franchise {
  id: string;
  logo: string;
  name: string;
  description: string;
  isOfficial?: boolean;
  userId: string;
  categoryId?: string;
}

export interface FranchiseDTO extends Franchise {
  stores: StoreDTO[];
}

const killBillAPI = axios.create({
  baseURL: `${APP_BASE_URL}`,
  timeout: 10000,
});

export const fetchKillBillOffersAPI = async (killBillId: string) => {
  const url = `/KillBills/${killBillId}/killBillOffers`;
  return killBillAPI.get<KillBillOffersDTO[]>(url, {
    params: {
      'filter[include]': 'store',
      'filter[include][franchise]': 'stores',
    },
  });
};

export const fetchKillBillUserAPI = async (userId: string, token: string) =>
  killBillAPI.get<KillBillUserDTO>('/KillBillUsers/findOne', {
    params: {
      filter: {
        where: {
          userId: userId,
          killBillId: KILLBILL_ID,
        },
      },
      access_token: token,
    },
  });

export const postClaimKillBillOffer = async (
  killBillUserId: string,
  offerId: KillBillOfferModel['id'],
  token: string,
) => {
  const url = `/KillBillUsers/${killBillUserId}/claimOffer`;
  return killBillAPI.post<KillBillOfferModel['id'][]>(url, {
    offerId,
    access_token: token,
  });
};

export const createKBLisbonAPI = async (token: string) => {
  const url = `${APP_BASE_ROUTER}/killbill/lisbon`;
  return axios.post(url, undefined, {
    params: {
      token,
    },
  });
};

export const fetchCardsRestApi = async () => {
  try {
    const { data } = await axios.get<number>(
      'https://littlebill-card-count.will635.workers.dev/',
    );
    return data;
  } catch (error) {
    return -1;
  }
};
