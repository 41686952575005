import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { checkmark } from 'ionicons/icons';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { userSelector } from '../../../../redux/selectors/user.selector';
import { resetPassword } from '../../api/auth.api';
import './ForgotPwdButton.css';

interface ForgotPwdButtonProps extends ForgotPwdButtonConnectedProps {
  className?: string;
}

const ForgotPwdButton: FC<ForgotPwdButtonProps> = ({ className }) => {
  const { t } = useTranslation();
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSuccess, setHasSuccess] = useState<boolean>();

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      setHasSuccess(undefined);
      await resetPassword(input.current?.value as string);
      setHasSuccess(true);
    } catch (error) {
      setHasSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onWillDismiss = () => {
    setIsLoading(false);
    setHasSuccess(undefined);
  };

  return (
    <>
      <p id="open-modal" className={className}>
        {t('account.forgotPassword')}
      </p>
      <IonModal ref={modal} trigger="open-modal" onWillDismiss={onWillDismiss}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {t('basics.actions.reset')} {t('basics.fields.password')}
            </IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>
                {t('basics.actions.cancel')}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>
            <IonLabel position="stacked">{t('account.provideEmail')}</IonLabel>
            <IonInput ref={input} type="text" placeholder={t('basics.fields.email')} />
          </IonItem>

          {hasSuccess ? (
            <div className="success-text">
              {t('account.resetLink')}
              <span>
                <IonIcon icon={checkmark}></IonIcon>
              </span>
            </div>
          ) : (
            <button disabled={isLoading} className="btn-auth" onClick={onConfirm}>
              {isLoading ? (
                <IonSpinner name="crescent" />
              ) : (
                <span>{t('basics.actions.reset')}</span>
              )}
            </button>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: userSelector(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ForgotPwdButtonConnectedProps = ConnectedProps<typeof connector>;

export default connector(ForgotPwdButton);
