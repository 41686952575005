import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import {
  isKillBillUserLoadingSelector,
  killBillUserSelector,
} from '../../../../redux/selectors/killBillUser.selector';
import { userSelector } from '../../../../redux/selectors/user.selector';
import './HeadBand.css';

interface HeadBandProps extends HeadBandConnectedProps {}

const HeadBand: FC<HeadBandProps> = ({ killBillUser, isKillBillUserLoading, user }) => {
  if (killBillUser && !navigator.share) {
    return null;
  }

  return (
    <div className="box-doree">
      <div>
        <p className="box-doree-text">
          Web<span>Summit</span> advantage pass
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: userSelector(state),
  killBillUser: killBillUserSelector(state),
  isKillBillUserLoading: isKillBillUserLoadingSelector(state),
});

const connector = connect(mapStateToProps);

type HeadBandConnectedProps = ConnectedProps<typeof connector>;

export default connector(HeadBand);
