import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdUnlock } from 'react-icons/io';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../../../redux/reducers';
import { killBillUserSelector } from '../../../../redux/selectors/killBillUser.selector';
import { userSelector } from '../../../../redux/selectors/user.selector';
import { ROUTES } from '../../../../shared/constantes/app.constantes';
import './CardKb.css';

interface CardKbProps extends CardKbConnectedProps {
  className?: string;
}

const CardKb: React.FC<CardKbProps> = ({ user, killBillUser, className = '' }) => {
  const { t } = useTranslation();
  const userName = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName} ${user?.lastName}`;
    }
    return user?.email;
  }, [user]);

  const history = useHistory();

  const onClickBuy = () => {
    history.push(ROUTES.buy);
  };

  return (
    <>
      <div className={`${className} card-container`}>
        <div className={!killBillUser ? 'on-blur' : ''}>
          <div className="logo">
            <div className="img"></div>
            <p className="text">
              Little<span>Bill</span>
            </p>
          </div>
          <div className="card-info-txt card-name">{userName ?? ''}</div>
          <div className="container-info-card">
            <div>
              <div className="card-info-txt info-row-txt-top">
                {t('basics.fields.city')}
              </div>
              <div className="card-info-txt info-row-txt-down">Lisbon</div>
            </div>
            <div>
              <div className="card-info-txt info-row-txt-top">
                {t('basics.common.expire')}
              </div>
              <div className="card-info-txt info-row-txt-down">11/06</div>
            </div>
          </div>
        </div>
        {!killBillUser ? (
          <div className="unlock-btn-container">
            <button onClick={onClickBuy}>
              <span>{t('basics.actions.buyNow')}</span> <IoMdUnlock fontSize="1.5em" />
            </button>
            <div>{t('killBill.limited')}</div>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  killBillUser: killBillUserSelector(state),
  user: userSelector(state),
});

const connector = connect(mapStateToProps);

type CardKbConnectedProps = ConnectedProps<typeof connector>;

export default connector(CardKb);
