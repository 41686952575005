import React, { useImperativeHandle, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { HiChevronDoubleRight } from 'react-icons/hi';
import { useMeasure } from 'react-use';

import './LBSlider.css';

const INITIAL_POSITION = { x: 0, y: 0 };

const SLIDER_BUTTON_WIDTH = 56;

export type LBSliderRef = { resetSlider: () => void };

interface LBSliderProps {
  onCompleteSwipe: () => void;
}

const LBSlider = React.forwardRef<LBSliderRef, LBSliderProps>(
  ({ onCompleteSwipe }, ref) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    const [position, setPosition] = useState(INITIAL_POSITION);

    const [isControlled, setIsControlled] = useState(false);
    const [SliderBoxRef, { width: sliderBoxWidth }] = useMeasure<HTMLDivElement>();

    const sliderDisabled = {
      left: `${-(sliderBoxWidth - SLIDER_BUTTON_WIDTH)}px`,
      transition: isControlled ? 'none ' : ' 1s',
    };

    const onStartHandler = () => setIsControlled(true);

    const onDragHandler = (e: DraggableEvent, data: DraggableData) =>
      setPosition({ x: data.x, y: INITIAL_POSITION.y });

    const resetSlider = () => {
      setIsControlled(false);
      setPosition(INITIAL_POSITION);
    };

    const onStopHandler = () => {
      if (position.x >= sliderBoxWidth - SLIDER_BUTTON_WIDTH) {
        setPosition({ x: sliderBoxWidth - SLIDER_BUTTON_WIDTH, y: INITIAL_POSITION.y });
        onCompleteSwipe();
        return;
      }
      resetSlider();
    };

    useImperativeHandle(ref, () => ({
      resetSlider,
    }));

    return (
      <div className="slider-container" ref={SliderBoxRef}>
        <div className="slider-label">
          {isControlled
            ? t('killBill.slider.guideTitle')
            : t('killBill.slider.guideTitle2')}
        </div>
        <Draggable
          axis="x"
          nodeRef={nodeRef}
          bounds={{ left: 0, right: sliderBoxWidth - SLIDER_BUTTON_WIDTH }}
          position={position}
          onStart={onStartHandler}
          onDrag={onDragHandler}
          onStop={onStopHandler}>
          <div
            style={sliderDisabled}
            className={`slider ${isControlled ? 'slider-enabled' : 'slider'}`}
            ref={nodeRef}>
            <div className={isControlled ? 'icons-enabled' : 'icon-disabled'}>
              <HiChevronDoubleRight size="2em" />
            </div>
          </div>
        </Draggable>
      </div>
    );
  },
);

export default LBSlider;
