import { getPlatforms } from '@ionic/react';
import { StoreDTO } from '../../main/offers/api/killbill.api';

export const isAndroid = getPlatforms().includes('android');
export const isIOS = getPlatforms().includes('ios');

export const openMapsApp = (store?: StoreDTO) => {
  if (!store) {
    return;
  }
  const address = `${store.storeAddress.line1},${store.storeAddress.city}`;

  if (isAndroid && store) {
    window.location.href = `geo:${store.location.lat},${store.location.lng}?q=${encodeURI(
      address,
    )}`;
  } else if (isIOS) {
    window.open(`https://maps.apple.com/?address=${address}`, '_blank');
  } else {
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`, '_blank');
  }
};
