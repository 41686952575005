export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_LOGIN = 'AUTH_LOGIN';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const USER_UPDATE = 'USER_UPDATE';
export const UPDATE_LOGIN_STATE = 'UPDATE_LOGIN_STATE';
export const UPDATE_SUBSCRIPTION_STATE = 'UPDATE_SUBSCRIPTION_STATE';
export const UPDATE_USER_LOCATION = 'UPDATE_USER_LOCATION';
export const UPDATE_USER_LOCATION_ID = 'UPDATE_USER_LOCATION_ID';

export const KILLBILL_USER_FETCH = 'KILLBILL_USER_FETCH';
export const KILLBILL_USER_UPDATE = 'KILLBILL_USER_UPDATE';
export const SET_KILLBILL_USER_LOADING = 'SET_KILLBILL_USER_LOADING';
export const KILLBILL_OFFERS_UPDATE = 'KILLBILL_OFFERS_UPDATE';
export const KILLBILL_OFFERS_FETCH = 'KILLBILL_OFFERS_FETCH';

export const FETCH_KB_CARDS_REST = 'FETCH_KB_CARDS_REST';
export const UPDATE_NB_KILLBILL = 'UPDATE_NB_KILLBILL';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';

export const UPDATE_FILTERS_OFFERS = 'UPDATE_FILTERS_OFFERS';
export const UPDATE_FILTERS_MAP = 'UPDATE_FILTERS_MAP';

export const CLAIM_KILLBILL_OFFER = 'CLAIM_KILLBILL_OFFER';
export const SET_IS_CLAIMING = 'SET_IS_CLAIMING';
export const SUCCESS_ANIMATION = 'SUCCESS_ANIMATION';

export const LOG_OUT = 'LOG_OUT';
