import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../../redux/reducers';
import { killBillUserSelector } from '../../../redux/selectors/killBillUser.selector';
import { userSelector } from '../../../redux/selectors/user.selector';
import { ROUTES } from '../../../shared/constantes/app.constantes';
import CardKb from '../components/cardKb/CardKb';
import './ValidationBuyPage.css';

interface ValidationBuyPageProps extends ConnectedValidationBuyPageProps {}

const ValidationBuyPage: React.FC<ValidationBuyPageProps> = ({ user, killBillUser }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <IonPage>
      <IonContent fullscreen className="page-validation">
        <div className="container">
          <div className="circle-light">
            <div className="circle-dark"></div>
            <div className="circle-icon">
              <AiOutlineCheck color="#FFFFFF" fontSize="2em" />
            </div>
          </div>

          <div className="txt-congrats">
            {t('validation.congratulations', { name: user?.firstName })}
          </div>

          <div className="paid-section">
            <p className="txt-total-confirmation">{t('validation.confirmation')}</p>

            <div className="logo-amount">
              <div className="logo">
                <div className="img"></div>
                <p className="text">
                  Little<span>Bill</span>
                </p>
              </div>

              <div>
                <div className="txt-total-paid">{t('validation.totalPaid')}</div>
                <div className="txt-total-prix">
                  {t('validation.price', { priceNumber: '20' })}
                </div>
              </div>
            </div>
          </div>

          <CardKb />

          <button className="btn-offers" onClick={() => history.push(ROUTES.offers)}>
            {t('validation.seeOffers')}
          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: RootState) => ({
  killBillUser: killBillUserSelector(state),
  user: userSelector(state),
});

const connector = connect(mapStateToProps);

type ConnectedValidationBuyPageProps = ConnectedProps<typeof connector>;

export default connector(ValidationBuyPage);
// export default ValidationBuyPage;
