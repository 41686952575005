import { createSelector } from 'reselect';
import { RootState } from '../reducers';

const killBillUserStateSelector = (state: RootState) => state.killBill;

export const killBillUserSelector = createSelector(
  killBillUserStateSelector,
  (killBillUserState) => killBillUserState.killBillUser,
);

export const isKillBillUserLoadingSelector = createSelector(
  killBillUserStateSelector,
  (killBillUserState) => killBillUserState.isKBUserLoading,
);

export const killBillOffersSelector = createSelector(
  killBillUserStateSelector,
  (killBillUserState) => killBillUserState.killBillOffers,
);

export const successAnimationSelector = createSelector(
  killBillUserStateSelector,
  (killBillUserState) => killBillUserState.successAnimation,
);

export const listFiltersSelector = createSelector(
  killBillUserStateSelector,
  (killBillUserState) => killBillUserState.filters.offers,
);

export const mapFiltersSelector = createSelector(
  killBillUserStateSelector,
  (killBillUserState) => killBillUserState.filters.map,
);

export const redeemStateSelector = createSelector(
  killBillUserStateSelector,
  (killBillUserState) => killBillUserState.redeemState,
);

export const nbKillBillSelector = createSelector(
  killBillUserStateSelector,
  (killBillUserState) => killBillUserState.nbKillBill,
);
