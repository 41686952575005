import { LoadScript } from '@react-google-maps/api';
import React, { useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { fetchUserDataAction } from './redux/actions/auth.action';
import {
  fetchKbRestAction,
  fetchKillBillOffersAction,
} from './redux/actions/killBillUser.action';
import { updateUserLocation, updateUserLocationId } from './redux/actions/user.action';
import { GOOGLE_API_KEY } from './shared/constantes/app.constantes';

interface AppWrapperProps extends AppWrapperConnectedProps {
  children: React.ReactElement;
}

var POSITION_OPTIONS = {
  timeout: 10000,
  enableHighAccuracy: true,
  maximumAge: 0,
};

const AppWrapper: React.FC<AppWrapperProps> = ({
  children,
  fetchUserData,
  fetchKillBillOffers,
  fetchKbRest,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchKillBillOffers();
    fetchUserData();
    fetchKbRest();
  }, []);

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        dispatch(updateUserLocation(latitude, longitude));
      },
      (error) => console.error(error),
      POSITION_OPTIONS,
    );
    dispatch(updateUserLocationId(watchId));
  }, []);

  return <LoadScript googleMapsApiKey={GOOGLE_API_KEY}> {children}</LoadScript>;
};

const mapDispatchToProps = {
  fetchKillBillOffers: fetchKillBillOffersAction,
  fetchUserData: fetchUserDataAction,
  fetchKbRest: fetchKbRestAction,
};

const connector = connect(undefined, mapDispatchToProps);

type AppWrapperConnectedProps = ConnectedProps<typeof connector>;

export default connector(AppWrapper);
