import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { gift, map, personCircle } from 'ionicons/icons';
import { Provider } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import AuthRegisterPage from './app/main/auth/pages/register/AuthRegisterPage';
import OffersPage from './app/main/offers/pages/OffersPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import OfferPage from './app/main/offers/pages/OfferPage';
import './app/shared/theme/common.css';
import './app/shared/theme/variables.css';

import AppWrapper from './app/AppWrapper';
import RequiredAuth from './app/main/auth/components/RequiredAuth';
import AuthLoginPage from './app/main/auth/pages/login/AuthLoginPage';
import ProfilePage from './app/main/auth/pages/profile/ProfilePage';
import MapPage from './app/main/map/pages/MapPage';
import BuyPage from './app/main/offers/pages/BuyPage';
import KillBillCard from './app/main/offers/pages/KillBillCard';
import ValidationBuyPage from './app/main/offers/pages/ValidationBuyPage';
import sagaInstance from './app/redux/';
import reducerInstance from './app/redux/reducers';
import { ROUTES } from './app/shared/constantes/app.constantes';

import './i18n/index.ts';

import i18n from 'i18next';

setupIonicReact();

// create the saga middleware
const sagaMiddleware = createSagaMiddleware({
  onError: (error: Error, errorStack) => {
    console.error('[SAGA ERROR]', error, errorStack);
  },
});
// show redux dev tools
const composeEnhancers =
  typeof window === 'object' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({})
    : compose;
const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware /*other middleware*/),
  /* other store enhancers if any */
);

const store = createStore(reducerInstance, enhancer);
// then run the saga
sagaMiddleware.run(sagaInstance);

const App: React.FC = () => (
  <Provider store={store}>
    <IonApp>
      <AppWrapper>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route path={ROUTES.buy} exact component={BuyPage} />
              <Route exact path={ROUTES.map}>
                <MapPage />
              </Route>
              <Route path={ROUTES.register} exact component={AuthRegisterPage} />
              <Route path={ROUTES.login} exact component={AuthLoginPage} />
              <Route path={ROUTES.profile} exact component={ProfilePage} />
              <Route path={ROUTES.validation} exact component={ValidationBuyPage} />
              <Route path="/account">
                <RequiredAuth>
                  <p>Logged in</p>
                </RequiredAuth>
              </Route>
              <Route path={ROUTES.killbillCard} exact component={KillBillCard} />
              <Route path={ROUTES.offers} component={OffersPage} exact />
              <Route path={ROUTES.offer(':id')} component={OfferPage} exact />
              <Route exact path="/">
                <Redirect to={ROUTES.map} />
              </Route>
            </IonRouterOutlet>

            <IonTabBar slot="bottom">
              <IonTabButton tab="map" href={ROUTES.map}>
                <IonIcon icon={map} />
                <IonLabel>{i18n.t('navigation.map')}</IonLabel>
              </IonTabButton>

              <IonTabButton tab="offers" href="/offers">
                <IonIcon icon={gift} />
                <IonLabel>{i18n.t('navigation.offers')}</IonLabel>
              </IonTabButton>

              <IonTabButton tab="account" href="/account">
                <IonIcon icon={personCircle} />
                <IonLabel>{i18n.t('navigation.account')}</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </AppWrapper>
    </IonApp>
  </Provider>
);

export default App;
