import { IonContent, IonIcon, IonPage } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchKillBillUserAction,
  updateMapFilters,
} from '../../../redux/actions/killBillUser.action';
import { RootState } from '../../../redux/reducers';
import {
  isKillBillUserLoadingSelector,
  killBillOffersSelector,
  killBillUserSelector,
  mapFiltersSelector,
} from '../../../redux/selectors/killBillUser.selector';
import QuickFilters from '../../../shared/components/quickFilters/QuickFilters';
import { ROUTES } from '../../../shared/constantes/app.constantes';
import { KillBillOffersDTO } from '../../offers/api/killbill.api';
import BuyPageContent from '../../offers/pages/BuyPageContent';
import MapCard from '../components/card/MapCard';
import HeadBand from '../components/headband/HeadBand';
import Map from '../components/map/Map';
import './MapPage.css';

interface MapPageProps extends MapPageConnectedProps {}

const MapPage: React.FC<MapPageProps> = ({
  killBillUser,
  killBillOffers,
  selectedMapFilters,
}) => {
  const history = useHistory();
  const [KBOffers, setKBOffers] = useState<KillBillOffersDTO[]>([]);
  const [clickedMarker, setClickedMarker] = useState<KillBillOffersDTO>();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (selectedMapFilters.length > 0) {
      setKBOffers(
        killBillOffers.filter(
          (offer) =>
            offer.store?.categoryId &&
            selectedMapFilters.includes(offer.store.categoryId),
        ),
      );
    } else {
      setKBOffers(killBillOffers);
    }
  }, [selectedMapFilters, killBillOffers]);

  const onMapClick = () => setClickedMarker(undefined);

  const onMarkerClick = (offer: KillBillOffersDTO) => {
    setClickedMarker(offer);
    if (!killBillUser) {
      setShowPopup(true);
    }
  };

  const onClickCard = () => {
    if (killBillUser) {
      history.push(ROUTES.offer(clickedMarker?.id || ''));
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <HeadBand />

        <div
          className="map-box"
          style={{
            height: killBillUser ? '100%' : 'calc(100% - 57px)',
          }}>
          <div className="container-chips-map">
            <QuickFilters isMap={true} />
          </div>
          <div className="google-map-box">
            <Map
              onMarkerClick={onMarkerClick}
              onMapClick={onMapClick}
              killBillOffers={KBOffers}
            />
            {showPopup && (
              <div className="popup-buy">
                <div>
                  <BuyPageContent onShare={() => setShowPopup(false)} isPopup={true} />
                  <IonIcon icon={close} onClick={() => setShowPopup(false)} />
                </div>
              </div>
            )}
          </div>
          {clickedMarker !== undefined && clickedMarker.store && (
            <div className="store-card" onClick={onClickCard}>
              <MapCard offerId={clickedMarker.id} />
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: RootState) => ({
  killBillOffers: killBillOffersSelector(state),
  killBillUser: killBillUserSelector(state),
  selectedMapFilters: mapFiltersSelector(state),
  isKillBillUserLoading: isKillBillUserLoadingSelector(state),
});

const mapDispatchToProps = {
  fetchKillBillUser: fetchKillBillUserAction,
  updateMapFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type MapPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(MapPage);
