import { IonContent, IonPage, IonToast, useIonLoading } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegUserCircle } from 'react-icons/fa';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authRegisterAction } from '../../../../redux/actions/auth.action';
import { updateSubscriptionState } from '../../../../redux/actions/user.action';
import { RootState } from '../../../../redux/reducers';
import {
  loggingStateSelector,
  subscriptionStateSelector,
  userSelector,
} from '../../../../redux/selectors/user.selector';
import TextField from '../../../../shared/components/input/TextField';
import { ROUTES } from '../../../../shared/constantes/app.constantes';
import { isEmailValid, isPasswordValid } from '../../utils/validation.utils';
import './AuthRegisterPage.css';

interface AuthRegisterPageProps extends AuthRegisterPageConnectedProps {}

const AuthRegisterPage: React.FC<AuthRegisterPageProps> = ({
  user,
  authRegister,
  loggingState,
  subscriptionState,
  updateSubscriptionState,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [present, dismiss] = useIonLoading();

  const [data, setData] = useState({
    email: '',
    password: '',
    countryCode: 'PT',
  });

  const isMailValid = isEmailValid(data.email);
  const isPwValid = isPasswordValid(data.password);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (loggingState.isLogging) {
      present({
        message: t('account.alert.pending'),
      });
      return;
    }
    if (user && subscriptionState === 'sharing') {
      updateSubscriptionState(undefined);
      history.replace(ROUTES.validation);
    } else if (user && !subscriptionState) {
      history.replace(ROUTES.profile);
    }
    dismiss();
  }, [loggingState.isLogging, subscriptionState, dismiss, present]);

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const onClickRegisterHandler = () => {
    isMailValid && isPwValid ? authRegister(data) : setHasError(true);
  };

  const onClickLoginHandler = () => history.push(ROUTES.login);

  return (
    <IonPage>
      <IonContent fullscreen className="auth-register-page">
        {subscriptionState !== 'sharing' && (
          <h1 className="titre">{t('account.createAccount')}</h1>
        )}
        {subscriptionState === 'sharing' && (
          <h1 className="titre">{t('account.sharing')}</h1>
        )}
        {subscriptionState === 'sharing' && (
          <h4 className="subTitle">
            {t('basics.steps.last')}, {t('account.createAccount')}
          </h4>
        )}
        <div className="icon">
          <FaRegUserCircle color="#c1a30b" fontSize="1.5em" />
        </div>

        <div className="create-account">
          <TextField
            className="form-field"
            label={t('basics.fields.email')}
            type="text"
            name="email"
            onChange={handleInputChange}
            error={hasError && !isMailValid ? t('account.validationErrors.email') : ''}
          />

          <TextField
            className="form-field"
            label={t('basics.fields.password')}
            type="password"
            name="password"
            onChange={handleInputChange}
            error={hasError && !isPwValid ? t('account.validationErrors.password') : ''}
          />

          <div className="create-account-btns">
            <button className="btn-auth" onClick={onClickRegisterHandler}>
              {t('basics.actions.register')}
            </button>
            <div className="account-separator">
              <span>{t('basics.junctionWords.or')}</span>
            </div>
            <button className="link-auth" onClick={onClickLoginHandler}>
              {t('account.alreadyExist')}
              <span>{t('basics.actions.logIn')}</span>
            </button>
          </div>
        </div>
      </IonContent>

      <IonToast
        position="top"
        isOpen={!loggingState.isLogging && !!loggingState.error}
        message={loggingState.error}
        duration={1500}
        color="warning"
        icon="warning-outline"
      />
    </IonPage>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: userSelector(state),
  loggingState: loggingStateSelector(state),
  subscriptionState: subscriptionStateSelector(state),
});

const mapDispatchToProps = {
  authRegister: authRegisterAction,
  updateSubscriptionState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AuthRegisterPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(AuthRegisterPage);
